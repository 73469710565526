<template>
  <div class="introduce">
    <crumbs :item="title"></crumbs>
    <div class="main" v-if="policyLoanRateMain !== null">
      <main>
        <Editor :readonly="true" :content="policyLoanRateMain" :height="0"></Editor>
      </main>
    </div>
    <div class="empty" v-else>
      <empty></empty>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
import empty from "@/components/empty";
import Editor from "@/components/TinyMce";
export default {
  name:"policyLoanRate",
  data(){
    return{
      policyLoanRateMain: null,
      title: [
        {
          title: "新闻公告",
          link: "",
        },
        {
          title: "累积生息利率及贷款利率公告",
          link: `/azContent/introduce/${'AZ01506'}`,
        },
        {
          title: "历史累积生息/保单贷款年利率",
          link: "/azContent/policyLoanRate",
        },
      ],
    }
  },
  components: {
    crumbs,
    empty,
    Editor
  },
  created() {
      sessionStorage.setItem("MENU", "新闻公告");
  },
  mounted() {
    console.log(localStorage.getItem("policyLoanRate"))
    this.policyLoanRateMain = localStorage.getItem("policyLoanRate") || ''
    
  },
};
</script>

<style lang="less" scoped>
.introduce {
  .main {
    width: 100%;

    > .time {
      width: 1034px;
      text-align: right;
      margin: 5rem auto 2rem;
      font-size: 2.2rem;
    }

    > main {
      width: 1034px;
      // height: 700px;
      // border: 1px solid;
      margin: 0 auto 0rem;
    }
  }

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>